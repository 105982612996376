import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  BlueskyShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  BlueskyIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';

const ShareButtons = ({ title, description }) => {
    const currentUrl = window.location.href;
  
    return (
      <div className="calcubest-share-buttons flex gap-2 items-center justify-center">
        <FacebookShareButton url={currentUrl} quote={description}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <BlueskyShareButton url={currentUrl} title={title}>
       <BlueskyIcon size={32} round />
    </BlueskyShareButton>
    <WhatsappShareButton url={currentUrl} title={title}>
       <WhatsappIcon size={32} round />
    </WhatsappShareButton>
        <TwitterShareButton url={currentUrl} title={title} hashtags={["useful", "calculator"]}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={currentUrl} title={title} summary={description}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    );
  };

export default ShareButtons;