import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from "../logo.svg";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Check screen size and update isMobile state
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Tailwind's md breakpoint
    };

    // Check initial screen size
    checkScreenSize();

    // Add event listener to check screen size on resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // Dropdown component for desktop view
  const DesktopDropdown = ({ title, to, items }) => (
    <li className="dropdown dropdown-hover dropdown-end">
      <Link tabIndex={0} role="button" to={to}>
        {title}
      </Link>
      <ul
        tabIndex="0"
        className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
      >
        {items.map((item, index) => (
          <li key={index}>
            <Link to={item.to}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </li>
  );

  // Mobile-friendly main category link
  const MobileCategoryLink = ({ title, to }) => (
    <li>
        <summary><Link to={to}>{title}</Link></summary>
    </li>
  );

  // Define menu items
  const financeItems = [
    { to: "/finance/putapy", label: "Cash Secured Put" },
    { to: "/finance/callapy", label: "Covered Call" },
    { to: "/finance/apy", label: "APY Interest" },
    { to: "/finance/pricetowage", label: "Price to Wage" },
    { to: "/finance/hourlywage", label: "Annual Wage" },
    { to: "/finance/cpp", label: "Credit Card Rewards" },
    { to: "/finance/nycholiday", label: "NYC Holiday Tip Amount" },
    { to: "/finance/nycrent", label: "NYC Rent Affordability" },
    { to: "/finance/pizzacost", label: "Pizza Cost" }
  ];

  const techItems = [
    { to: "/tech/llmresources", label: "AI Environmental Impact" },
    { to: "/tech/oklch", label: "OKCLH Picker" },
    { to: "/tech/subnet", label: "Subnet Calculator" },
    { to: "/tech/vlsm", label: "Subnetting Calculator" }
  ];

  const healthItems = [
    { to: "/health/runningpace", label: "Running Pace" },
    { to: "/health/calories", label: "Exercise To Food Calories" },
    { to: "/health/tdee", label: "TDEE" },
    { to: "/health/height", label: "Height Calculator" },
    { to: "/health/smoothie", label: "Smoothie Calculator" },
    { to: "/health/triathlon", label: "Triathlon time" },
  ];

  return (
    <header className="navbar bg-base-200 shadow-lg">
      <div className="flex-1 flex items-center">
        <a href="/" className="flex items-center space-x-2">
          <img
            src={logo}
            alt="Site Logo"
            className="w-10 h-10 object-contain"
          />
          { !isMobile && <span className="text-xl font-bold primary-content">CalcuBest</span> }
        </a>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal p-0">
          {isMobile ? (
            <>
              <MobileCategoryLink 
                title="Finance" 
                to="/finance" 
              />
              <MobileCategoryLink 
                title="Tech" 
                to="/tech" 
              />
              <MobileCategoryLink 
                title="Health" 
                to="/health" 
              />
            </>
          ) : (
            <>
              <DesktopDropdown 
                title="Finance" 
                to="/finance" 
                items={financeItems} 
              />
              <DesktopDropdown 
                title="Tech" 
                to="/tech" 
                items={techItems} 
              />
              <DesktopDropdown 
                title="Health" 
                to="/health" 
                items={healthItems} 
              />
            </>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Header;