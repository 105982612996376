import React from "react";
import ShareButtons from './ShareButtons';

const Footer = () => {
  return (
        <footer className="text-center mt-6">
          {/* TODO: Move to individual pages with title and description */}
          <ShareButtons />
            <p className="text-sm text-gray-500">
                Copyright. For feedback please email info@calcubest.com.
            </p>
        </footer>
  );
};

export default Footer;
